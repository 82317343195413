.button {
  background: var(--buttonPrimary);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  border-radius: 4px;
  border: 0;
  color: var(--textPrimary);
  font-size: var(--fontSizeSecondary);
  line-height: 2em;
  padding-left: 1em;
  padding-right: 1em;
  position: relative;
}

.heedful {
  background: var(--buttonHeedful);
}

.large {
  font-size: 22px;
  padding: 1em 5em;
}

.button:hover {
  opacity: 0.9;
}

.button:active {
  top: 0.5px;
}
