.container {
  padding: 0 1em 0 1em;
  width: calc(100% - 2em);
  overflow: auto;
  flex: 1;
}

.challenge {
  font-size: 12px;
  line-height: 1.5em;
}
