.container {
  display: flex;
  flex-direction: row;
  padding: 0.75em;
  align-items: center;
  justify-content: center;
  border-top: 1px solid var(--low);

  form {
    margin-left: 1em;
    /* Group 8 */

    box-sizing: border-box;

    background: rgba(0, 0, 0, 0.12);
    border: 1px solid rgba(0, 0, 0, 0.49);
    border-radius: 5px;
    display: flex;

    input[type="email"] {
      background: transparent;
      border: 0;
      padding: 0 1em;
      width: 220px;
      outline: none;
    }

    input[type="submit"] {
      margin: 0.25em;
      background: var(--buttonPrimary);
      height: 28px;
      border: 0;
      border-radius: var(--borderRadius);
      padding: 0 1em;
    }
  }
}

.hide {
  position: absolute;
  left: -999999px;
}
