.container {
  padding: 1em;
}

.videos {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  grid-gap: 20px;
  margin: 1em;
  h3 {
    margin: 0;
    padding: 0;
    padding-bottom: 0.25em;
  }

  p {
    padding: 0;
    margin: 0;
    padding-bottom: 0.5em;
    font-style: italic;
    font-size: 10px;
  }
}

.video {
  position: relative;
  max-width: 250px;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: 1px solid var(--backgroundLight);
  padding: 1em;
  border-radius: var(--borderRadius);
  video {
    width: 100%;
    margin-bottom: 5px;
  }

  button {
    position: absolute;
    top: 0;
    right: 0;
    transform: scale(60%);
  }
}
