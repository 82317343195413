.container {
  cursor: default;
  z-index: 100;
}

.menu {
  z-index: 10;
  max-height: 250px;
  overflow: auto;
  box-shadow: var(--overlayBoxShadow);
  font-weight: bold;
  border-radius: var(--borderRadius);
  position: fixed;
  background: var(--overlayBackground);
  color: var(--overlayText);
  list-style: none;
  font-weight: normal;
  margin: 0;
  padding: 4px;
  font-size: var(--fontSizeMenu);

  &:focus {
    outline: none;
  }

  li {
    cursor: default;
    position: relative;
    padding: 5px 40px;
    margin: 2px;
    border-radius: var(--borderRadius);

    > svg {
      position: absolute;
      left: 15px;
      bottom: 7px;
    }
  }

  > svg {
    position: relative;
    top: -1px;
  }
}

.spacer {
  width: 0px;
  display: inline-block;
}

.hover {
  background: rgba(0, 0, 0, 0.1);
}
