.container {
  align-items: stretch;
  position: relative;
  border-radius: 15px;
  background: var(--buttonPrimary);
  width: calc(75%);
  aspect-ratio: 450/250;
  display: flex;
  margin: auto;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  cursor: default;
}

.video {
  min-width: 100%;
  min-height: 100%;
  transform: scaleX(-1);
}
