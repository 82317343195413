:root {
  --background: #1f242f;
  --backgroundLight: #2d3341;
  --surface: #3a4354;
  --overlay: rgba(255, 255, 255, 0.15);
  --low: rgba(0, 0, 0, 0.25);

  --buttonPrimary: #5473b0;
  --buttonSecondary: #3c4b6c;
  --buttonHeedful: #940000; // Take headed of, take headful

  --textPrimary: #fff;
  --textSecondary: #d9d9d9;
  --textAlert: #f00;

  --boxShadowPrimary: 0px 1px 1px rgba(0, 0, 0, 0.25);
  --borderRadius: 4px;

  --overlayBoxShadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  --overlayBackground: #fff;
  --overlayText: #000;

  --fontSizePrimary: 18px;
  --fontSizeSecondary: 16px;
  --fontSizeMenu: 14px;
}
