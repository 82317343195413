.backdrop {
  background: rgba(0, 0, 0, 0.4);
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 10;
  display: flex;
  align-items: center;
  justify-content: center;
}

.modal {
  border-radius: var(--borderRadius);
  background: var(--overlayBackground);
  box-shadow: var(--overlayBoxShadow);
  color: var(--overlayText);
  padding: 2em;

  a,
  a:visited {
    color: var(--buttonPrimary);
  }
}
