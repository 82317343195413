@import url("https://fonts.googleapis.com/css2?family=Commissioner:wght@400;500;600;700&family=Inter&display=swap");
@import "./vars.scss";

.App {
  height: 100vh;
  display: flex;
  flex-direction: column;
}

code {
  font-family: monospace;
  background: var(--overlay);
  border: 1px solid var(--surface);
  color: var(--textPrimary);
  padding: 0 2px;
  border-radius: var(--borderRadius);
}

body,
button,
input {
  font-family: "Commissioner", sans-serif;
  font-family: "Inter", sans-serif;
  color: var(--textPrimary);
}

body {
  background: var(--background);
}

a:link,
a:visited {
  color: #ff0;
}

table {
  width: 100%;
}

th {
  font-weight: bold;
  background: var(--backgroundLight);
  width: 50%;
  padding: 0.25em;
  text-align: left;
}

td {
  padding: 0.25em;
  border-bottom: 1px solid var(--textSecondary);
  vertical-align: top;
}

tr {
  margin: 0;
}
