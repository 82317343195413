.container {
  background: var(--backgroundLight);
  box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.25);
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  a {
    margin-left: 2em;
  }

  > div {
    display: flex;
    align-items: center;
  }

  img {
    cursor: pointer;
  }
}

.title {
  font-size: 18px;
  font-style: italic;
}
