.content {
  font-size: 18px;
  flex: 1;
  padding-bottom: 20px;
  padding-top: 50px;
  margin: auto;
  width: 75%;
  display: flex;
  flex-direction: column;
  overflow: auto;

  button {
    margin: auto;
  }
}

.buttonWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
}
