.container {
  width: 475px;
  background: var(--surface);
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  flex-direction: column;
  display: flex;
  overflow: hidden;
  hr {
    margin-top: 20px;
    margin-bottom: 20px;
    width: 100%;
    border: 0;
    height: 1px;
    background: rgba(0, 0, 0, 0.3);
  }
}
