.container {
  display: flex;
  flex-direction: column;
  flex: 1;
  overflow: hidden;
}

.titleBar {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  background: var(--low);
  padding-right: 0.5em;
}

.tabs {
  height: 100%;
}

.tabs,
.tabs > div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

.tabs > div {
  cursor: pointer;
  padding: 0.5em 1em;
  height: 100%;
}

.active {
  background: var(--background);
}
